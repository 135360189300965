<template>
  <div>
  </div>
</template>

<script>

export default {
  mounted () {
    window.open('http://localhost:8080/Fees.pdf', '_blank');
  }
}
</script>
